.switch {
  min-width: 72px;
  .switch__text-left {
    margin-right: 8px;
  }
  .switch__text-right {
    margin-left: 8px;
  }
}
.switch__btn:global(.ant-switch.ant-switch-checked) {
  background-color: var(--main-orange-8);
}
