.border {
  border-style: solid;
}
.border--width-1 {
  border-width: 1px;
}
.border--width-2 {
  border-width: 2px;
}
.border--left {
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}
.border--right {
  border-top-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
}
.border--top {
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
}
.border--bottom {
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
}
.border--horizontal {
  border-top-width: 0;
  border-bottom-width: 0;
}
.border--vertical {
  border-right-width: 0;
  border-left-width: 0;
}
.border--erase-top {
  border-top-width: 0;
}
.border--erase-bottom {
  border-bottom-width: 0;
}
.border--erase-left {
  border-left-width: 0;
}
.border--erase-right {
  border-right-width: 0;
}
.border--color-gray-3 {
  border-color: var(--main-blue-gray-3);
}
.border--color-gray-35 {
  border-color: var(--main-gray-35);
}
.border--color-gray-9 {
  border-color: var(--main-gray-9);
}
.border--color-white {
  border-color: var(--white);
}
.border--color-cta-12 {
  border-color: var(--cta-12);
}
.border--gutter-none {
  padding: 0;
}
.border--gutter-4 {
  padding: 4px;
}
.border--gutter-8 {
  padding: 8px;
}
.border--gutter-12 {
  padding: 12px;
}
.border--gutter-14 {
  padding: 14px;
}
.border--gutter-16 {
  padding: 16px;
}
.border--gutter-24 {
  padding: 24px;
}
.border--gutter-position-left {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.border--gutter-position-right {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.border--gutter-position-top {
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.border--gutter-position-bottom {
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
}
.border--gutter-position-vertical {
  padding-right: 0;
  padding-left: 0;
}
.border--gutter-position-horizontal {
  padding-top: 0;
  padding-bottom: 0;
}
.border--radius-2 {
  border-radius: 2px;
}
.border--radius-4 {
  border-radius: 4px;
}
.border--radius-6 {
  border-radius: 6px;
}
.border--radius-circle {
  border-radius: 50%;
}
